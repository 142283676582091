import React from 'react';
import Section from 'components/HOC/Section';

const IconBoxItemOne = ({data, border, padding, iconType, className, showBtn}) => {
    return (
        <Section id="aboutUs">
            <div
                className={`${className} icon-box mt--30 text-center ${border ? '' : 'no-border'} ${!border && padding ? 'with-padding' : ''}`}>
                <div className="inner">
                    <div className="content">
                        <h5 className="heading heading-h5">About Us</h5>
                        <p className="bk_pra">Welcome to Australia Ocean, 
                        your trusted partner in trade. 
                        As an Australian trade company, 
                        we are committed to facilitating 
                        seamless commerce for businesses across various industries. 
                        Our mission is to empower businesses of all sizes to 
                        expand their horizons, 
                        connect with markets, 
                        and navigate the complexities of trade with confidence.</p>
                    </div>
                </div>
            </div>
        </Section>
        
    );
};

export default IconBoxItemOne;