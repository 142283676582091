import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import IconBoxItem from "components/icon-box/IconBoxItemOne";

const IconBoxOne = () => {
    return (
            <div className="brook-icon-boxes-area bg_color--1 mt_sm--50 mt_md--70">
                <Container>
                    <Row>
                        <Col xs={12} md={12} key={1} className="mt--n150 mt_sm--0 mt_md--0">
                            <IconBoxItem border={true} padding={true}/>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
};

export default IconBoxOne;