import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Input from "components/input";
import Section from 'components/HOC/Section';

const ContactOne = () => {
    return (
        <Section id="contactUs">
            <div className="bk-contact-area section-pb-xl bg_color--1 section-pt-xl">
            <Container>
                <Row>
                    <Col xs={12} lg={3}>
                        <div className="address-inner">
                            <div className="address wow move-up">
                                <h3 className="heading">Contact us</h3>
                                <p><strong>Phone</strong>: (+61) 431 265 103</p>
                                <p><strong>Address</strong>: 795 Princes Highway, Springvale, VIC 3171</p>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={9} xl={{span: 8, offset: 1}} className="mt_md--40 mt_sm--40">
                        <div className="contact-form">
                            <form action="/" className="form-style--1 wow move-up">
                                <div className="row">
                                    <Col lg={6}>
                                        <div className="input-box">
                                            <span className="form-icon fa fa-user-o"/>
                                            <Input
                                                type={"text"}
                                                name={"name"}
                                                placeholder={"Name*"}
                                                required={true}
                                            />
                                        </div>
                                    </Col>

                                    <Col lg={6}>
                                        <div className="input-box">
                                            <span className="form-icon fa fa-envelope-o"/>
                                            <Input
                                                type={"email"}
                                                name={"email"}
                                                placeholder={"Email*"}
                                                required={true}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="input-box">
                                            <span className="form-icon fa fa-phone"/>
                                            <Input
                                                type={"text"}
                                                name={"phone"}
                                                placeholder={"Phone Number"}
                                                required={false}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="input-box">
                                            <Input
                                                type={"textarea"}
                                                name={"message"}
                                                placeholder={"Your message*"}
                                                required={true}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="form-action">
                                            <div className="form-submit submit-button">
                                                <a href="/">Send Message</a>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
}

export default ContactOne;
