import WOW from "wowjs";
import React, {useEffect} from 'react';
import ScrollToTop from "./utils/scrollToTop";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";


import Error from "./pages/others/Error";
import AboutPageFour from "./pages/about/AboutPageFour";


const App = () => {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, []);
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL}`}
                        component={AboutPageFour}
                    />

                    <Route exact component={Error}/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
};

export default App;