import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import IconListItem from "components/icon-list/IconListItemOne";
import Section from 'components/HOC/Section';

const AboutPageArea2 = () => {
    return (
        <Section id="services">
        <div className="brook-feature-area ptb--150 ptb_md--80 ptb_sm--60 bg_color--1">
            <Container>
                <Row>
                    <Col>
                        <div className="brook-section-title mb--10">
                            <h3 className="heading heading-h3">Our Services</h3>
                        </div>

                        <Row>
                            {content.map(item => (
                                <Col md={6} className="mt--50" key={item.id}>
                                    <IconListItem data={item}/>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
};

const content = [
    {
        id: 1,
        iconClass: "ion-android-globe",
        title: "Import and Export",
        content: "We facilitate the import and export of goods, helping you expand your reach to global markets or access international suppliers."
    },
    {
        id: 2,
        iconClass: "ion-ios-infinite",
        title: "Supply Chain Management",
        content: "We optimize your supply chain for maximum efficiency, reducing costs and improving overall performance."
    },
    {
        id: 3,
        iconClass: "ion-monitor",
        title: "Compliance and Risk Management",
        content: "Trade comes with its fair share of regulations and risks. We provide comprehensive guidance on compliance and risk management, ensuring that your business operates smoothly and avoids costly pitfalls"
    },
    {
        id: 4,
        iconClass: "ion-pinpoint",
        title: "Tailored Solutions",
        content: "We understand that each business is unique. That's why we offer personalized solutions that cater to your specific needs and goals. Whether you are a small startup or an established corporation, we have the tools and expertise to assist you."
    },
]

export default AboutPageArea2;
